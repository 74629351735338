@import 'variables/variables';

@import 'components/index';

:root {

  //--ion-safe-area-top: 20px;
  //--ion-safe-area-bottom: 20px;

  --custom-padding-top: 0;
  --custom-padding-bottom: 0;

  --custom-padding-top-header-md: 0px;

  --max-width-app: 820px;
}

html {
  height: 100%
}

body {
  height: 100%;
}

body {
  max-width: var(--max-width-app);
  margin: 0 auto !important;
  float: none !important;
  position: relative;
}

.plt-cordova.md {
  //--ion-safe-area-bottom: var(--safe-area-inset-bottom, 0);
  //--ion-safe-area-top: var(--safe-area-inset-top, 0);
  //body {
  //  height: calc(100% - var(--ion-safe-area-bottom, 0))
  //}
}

body.desktop {
  margin: 20px auto !important;
  height: calc(100vh - 40px);
  overflow: scroll;
  border-radius: 16px;
  .ion-page {
    border-radius: 16px;
  }

  .modal-wrapper {
    margin-top: 24px;
    margin-bottom: 24px;
    --height: calc(100vh - 24px) !important;
  }

    .bottom_container {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

}

.plt-cordova .md {
  --custom-padding-top-header-md: 20px;
}

.plt-desktop {
  .img-container {
    height: auto !important;
    max-height: 40vh !important;
    width: auto !important;
  }
}

ion-content {
  //--keyboard-offset: 0px !important;
}

.md ion-header {
  padding-top: var(--custom-padding-top-header-md) !important;
}

.bottom-safe-area {
  margin-bottom: calc(var(--ion-safe-area-bottom) + 20px);
}

.bottom-padding-safe-area {
  padding-bottom: var(--ion-safe-area-bottom);
}

body, .ion-page {
  background: var(--ion-color-white) !important;
}

.padding-20 {
  padding: var(--app-padding);
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.fixed-button {
  max-width: var(--max-width-app);
}

app-c-footer {
  max-width: var(--max-width-app);
}

* {
  outline: none;
}

ion-icon {
  color: var(--ion-color-main-text);
}

ion-picker {
  --max-width: 100% !important;
}

app-refresher {
  width: 100%;
  //z-index: 10;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.alert .alert-wrapper {
  white-space: pre-line;
}

::selection {
  background: rgba(0, 122, 255, 0.3);
}

::-moz-selection {
  background: rgba(0, 122, 255, 0.3);
}

.popover_center {
  --width: calc(100% - 40px);
  --backdrop-opacity: 0.5 !important;

  .popover-wrapper .popover-content {
    border-radius: 16px;
    left: 0 !important;
    top: calc(45vh + var(--custom-padding-top-header-md) + var(--ion-safe-area-top) - 55px) !important;
    margin: 0 20px;
    width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 360px) {
  .popover_center {
    --width: calc(100% - 10px);

    .popover-wrapper .popover-content {
      margin: 0 5px;
      width: calc(100% - 10px);
    }
  }
}

@media only screen and (min-width: 768px), (min-height: 768px) {
  ion-modal {
    --width: 100vw !important;
    --height: 100% !important;
    --max-width: var(--max-width-app);
  }
}

ion-modal[trigger="date-modal"] {
  --width: unset !important;
  --height: unset !important;
  --max-width: unset;
}

.carrot-frame-bumperCookies-agreed {
  display: none;
}

