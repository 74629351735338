/** Custom CSS Variables **/

:root {
    --app-padding-start: 20px;
    --app-padding-end: 20px;
    --app-padding-top: 20px;
    --app-padding-bottom: 20px;

    --app-element-gap: 20px;
}

@media (max-width: 355px) {
    :root {
        --app-padding-start: 16px;
        --app-padding-end: 16px;
        --app-padding-top: 16px;
        --app-padding-bottom: 16px;

        --app-element-gap: 12px;
    }
}

// TODO: Old
:root {
    --app-padding: 20px;
}

// TODO: Old
@media (max-width: 355px) {
    :root {
        --app-padding: 16px;
    }
}

// class
// TODO: Old
.app-padding {
    padding: var(--app-padding);
}

.app-padding-hor {
    padding: 0 var(--app-padding);
}

.app-padding-vert {
    padding: var(--app-padding) 0;
}
