// Font Families
// -------------

@font-face {
  font-family: "Circe";
  src: url("/assets/fonts/Circe-Regular.ttf");
}

@font-face {
  font-family: "Circe";
  font-weight: bold;
  src: url("/assets/fonts/Circe-Bold.ttf");
}

@font-face {
  font-family: "Rubik";
  src: url("/assets/fonts/Rubik-Regular.ttf");
}

@font-face {
  font-family: "Rubik";
  font-weight: 500;
  src: url("/assets/fonts/Rubik-Medium.ttf");
}

@font-face {
  font-family: "Rubik";
  font-weight: bold;
  src: url("/assets/fonts/Rubik-Bold.ttf");
}

.font_bold {
  font-weight: bold;
  font-style: normal;
}

.font_medium {
  font-weight: 500;
  font-style: normal;
}

.font_regular {
  font-weight: normal;
  font-style: normal;
}

.text-center {
  text-align: center;
}

h1,h2,h3,h4,h5 {
  margin: 0;
}

h3 {
  font-family: Circe;
  font-size: 32px;
  line-height: 40px;
}

h4 {
  font-family: Circe;
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-family: Circe;
  font-size: 20px;
  line-height: 28px;
}

.sub_circe {
  font-family: Circe;
  font-size: 18px;
  line-height: 24px;
}

.sub_rubik {
  font-family: Rubik;
  font-size: 18px;
  line-height: 24px;
}

.body_circe {
  font-family: Circe;
  font-size: 16px;
  line-height: 24px;
}

.body_rubik {
  font-family: Rubik;
  font-size: 16px;
  line-height: 24px;
}

.body_secondary_circe {
  font-family: Circe;
  font-size: 14px;
  line-height: 20px;
}

.body_secondary_rubik {
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
}

.caption_circe {
  font-family: Circe !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.caption_rubik {
  font-family: Rubik !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.caption_small_rubik {
  font-family: Rubik !important;
  font-size: 10px !important;
  line-height: 12px !important;
}


.overline_circe {
  font-family: Circe !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 8px !important;
  line-height: 12px !important;
  text-transform: uppercase !important;
}
