/** Custom CSS Variables **/
:root {

  --ion-color-primary: #007AFF;

  /** primary **/
  --ion-color-accent: #007AFF;

  /** primary text **/
  --ion-color-main-text: #1D2E69;

  /** secondary text **/
  --ion-color-secondary-text: #616A88;

  /** additional text **/
  --ion-color-additional-text: #9DA6BA;

  /** placeholder **/
  --ion-color-placeholder: #B0B5C4;

  /** border active **/
  --ion-color-border-active: #E4E8EE;

  /** background and dividers **/
  --ion-color-background-divider: #F6F8FB;

  /** stars **/
  --ion-color-stars: #FFD254;

  /** success **/
  --ion-color-success: #00ADB5;
  --ion-color-success--rgb: 0, 173, 181;
  /** warning **/
  --ion-color-warning: #FFD254;
  --ion-color-warning--rgb: 255, 210, 84;
  /** error **/
  --ion-color-error: #FF4D5B;

  /** white **/
  --ion-color-white: #FFF;

  /** black **/
  --ion-color-black: #000;

  --ion-color-green: #2dd36f;

  /** transparent **/
  --ion-color-transparent: transparent;

  --ion-color-menu-item: #9d9daa;

}

